<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
    </div>

    

    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link mr-1"
      >
        <template #button-content>
          <feather-icon
            icon="BellIcon"
            size="21"
            class="text-primary"
            badge="3"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" >
          <div
            class="d-flex justify-content-start align-items-center mb-1"
          >
            <b-avatar
              :src="require('@/assets/images/avatars/user_2.png')"
              size="50"
              class="mr-1"
            />
            <div class="profile-user-info">
              <h6 class="mb-0">
                Nova atividade postada!
              </h6>
              <small class="text-muted">13 de Outubro de 2021</small>
            </div>
          </div>
        </b-dropdown-item>

       <b-dropdown-item link-class="d-flex align-items-center" >
          <div
            class="d-flex justify-content-start align-items-center mb-1"
          >
            <b-avatar
              :src="require('@/assets/images/avatars/user_2.png')"
              size="50"
              class="mr-1"
            />
            <div class="profile-user-info">
              <h6 class="mb-0">
                Nova atividade postada!
              </h6>
              <small class="text-muted">6 de Outubro de 2021</small>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" >
          <div
            class="d-flex justify-content-start align-items-center mb-1"
          >
            <b-avatar
              :src="require('@/assets/images/avatars/user_2.png')"
              size="50"
              class="mr-1"
            />
            <div class="profile-user-info">
              <h6 class="mb-0">
                Nova atividade postada!
              </h6>
              <small class="text-muted">4 de Outubro de 2021</small>
            </div>
          </div>
        </b-dropdown-item>
        <b-dropdown-item link-class="d-flex align-items-center" :href="'/notificacoes'" class="text-center">
          Visualizar todas as notificações
        </b-dropdown-item>
      </b-nav-item-dropdown> -->
      <b-link class="mr-1" :href="'/notificacoes'">
        <feather-icon
          icon="BellIcon"
          size="21"
          class="text-primary"
          :badge="countNotification"
        />
      </b-link>
      <!-- User -->
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user ? user.name : 'Não logado' }}
            </p>
            <span class="user-status"> {{ 
              user ? userTypes[user.type] : 'Nenhum perfil' }} </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="user.photo"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{ path: '/perfil'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Sair</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import axios from "@axios";
import { mapActions, mapState } from "vuex";
import { initialAbility } from '@/libs/acl/config'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  directives: {
    Ripple,
  },
  data(){
    return {
      countNotification: 0,
      userTypes: {
        'ADMIN' : 'ADMINISTRADOR',
        'COMPANY_ADMIN' : 'GESTOR DE EMPRESA',
        'SCHOOL_ADMIN' : 'GESTOR DE ESCOLA',
        'TEACHER' : 'PROFESSOR', 
        'STUDENT' : 'ALUNO',
      },
    }
  },
  computed: {
    ...mapState("notifications", ["notifications"]),
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  created(){
    this.fetchNotificationCount();
  },
  methods: {
    logout() {
      axios.post("logout", {}).then(() => {
          localStorage.removeItem('userData')
          localStorage.removeItem(["accessToken"]);
          localStorage.removeItem(["expiresIn"]);
          localStorage.clear();

          // Reset ability
          this.$ability.update(initialAbility)

          this.$router.push({ name: 'login' })
      })
    },

    fetchNotificationCount() {
      this.$store
        .dispatch("notifications/fetchCountNotification")
        .then((response) => {
          response.forEach((value, index) => {
            this.countNotification++
          })
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
        });
    },
  },
  computed: {
    user(){
      return JSON.parse(localStorage.getItem('userData'))
    }
  },
}
</script>
