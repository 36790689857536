export default [
  {
    header: 'Conteúdo',
    resource: 'home',
    action: 'read',
  },
  {
    title: 'Fases',
    route: 'phases',
    icon: 'BoxIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Módulos',
    route: 'modules',
    icon: 'LayersIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Habilidades',
    route: 'abilities',
    icon: 'PackageIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Competências',
    route: 'competences',
    icon: 'PaperclipIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Cadernos de atividades',
    route: 'books',
    icon: 'BookIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Empresas',
    route: 'companies',
    icon: 'CheckSquareIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Assinatura',
    route: 'contracts',
    icon: 'CalendarIcon',
    resource: 'ADMIN',
  },
  {
    title: 'Minha empresa',
    route: 'contracts-by-company',
    icon: 'FileIcon',
    resource: 'MY-COMPANY',
  }, 
  {
    title: 'Escolas',
    route: 'schools',
    icon: 'GridIcon',
    resource: 'SCHOOL_ADMIN',
  },
/*   {
    title: 'Aulas',
    route: 'release-classes',
    icon: 'BookmarkIcon',
    resource: 'menu.lessons',
  }, */
  {
    title: 'Mural',
    route: 'murals',
    icon: 'MonitorIcon',
    resource: 'home',
  },
  {
    title: 'Murals',
    icon: 'FileTextIcon',
    tag: '0',
    tagVariant: 'light-primary',
    resource: 'ADMIN',
    children: [
      {
        title: '#',
        icon: 'XIcon',
      },
    ],
  },
]
